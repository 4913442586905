import React, { useEffect } from 'react';

import './Instructions.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { instructionsSelector } from '../../store/ducks/instructions/selectors/instructionsSelector';
import { StateStatus } from '../../types/models';
import { getInstructions } from '../../store/ducks/instructions/actions';

export function Instructions() {
    const dispatch = useDispatch();

    const status = useSelector(
        (state: { instructions: { status: StateStatus } }) =>
            state.instructions.status
    );

    const { instructions } = useSelector(
        instructionsSelector
    );

    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(getInstructions());
        }
    }, []);

    return (
        <>
            {/*<section className="instructions page__instructions">*/}
            {/*    <div className="instructions__container">*/}
            {/*        <div className="instructions__list">*/}
            {/*            {instructionsPosts.map(instruction => {*/}
            {/*                return (*/}
            {/*                    <Link*/}
            {/*                        to={instruction.pathTo}*/}
            {/*                        className="instructions__items"*/}
            {/*                        key={instruction.id}*/}
            {/*                    >*/}
            {/*                        <div className="instructions__img-ibg">*/}
            {/*                            <img*/}
            {/*                                src={instruction.image}*/}
            {/*                                alt="img"*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                        <h3 className="instructions__title">*/}
            {/*                            {instruction.title}*/}
            {/*                        </h3>*/}
            {/*                    </Link>*/}
            {/*                );*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    );
}
