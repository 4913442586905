import { createSelector } from 'reselect';

import {
    getFullPath,
    removeAllHTMLAndUnicodeSymbols,
} from '../../../helpers/helpers';
import { displayDate } from '../../../helpers/displayDate.helpers';

//
const instructions = (state: { instructions: { instructions: any[] } }) =>
    state.instructions.instructions;

export const instructionsSelector = createSelector(
    [instructions],
    instructions => {
        let renderInstructions: any[] = [];
        if (instructions && instructions.length) {
            instructions.map((i: any) => {
                let tag = '',
                    object = {
                        id: i.id,
                        date: displayDate(i.date),
                        title: removeAllHTMLAndUnicodeSymbols(i.title.rendered),
                        tag: i.tag || tag,
                        type: i.type,
                        slug: i.slug,
                        pathTo: getFullPath(['instructions', i.slug]),
                        image: i.images.full || '',
                    };

                renderInstructions.push(object);
            });
        }

        return { instructions: renderInstructions };
    }
);
