import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
// import loadable from '@loadable/component';
// import * as bem from 'b_';
// import {
//     Promo,
//     Logo,
//     MenuIcon,
//     Search,
//     Login,
//     Menu,
//     Submenu,
//     Promotions,
// } from 'components';

import './InfiniteLine.css';
// import { getNavbar } from 'store/ducks/menu/actions';
// import { menuSelector, submenuSelector } from 'store/ducks/menu/selector';
// import { MenuItem } from 'types/models';

// enum PageName {
//     Home = 'Home',
//     About = 'About',
//     Article = 'Article',
//     ArticleVideo = 'ArticleVideo',
//     Newsfeeds = 'Newsfeeds',
//     Upcoming = 'Upcoming',
// }

// const menu = [
//     { to: '/', exact: true, page: PageName.Home },
//     { to: '/about', exact: true, page: PageName.About },
//     { to: '/article', exact: true, page: PageName.Article },
//     { to: '/newsfeeds', exact: true, page: PageName.Newsfeeds },
//     { to: '/upcoming', exact: true, page: PageName.Upcoming },
// ];

// const preloadPage = (pageName: string) =>
//     loadable(() => import(`../../pages/${pageName}/${pageName}`));

type Props = {
    mod: string;
};

export function InfiniteLine(props: Props) {
    const { mod } = props;
    // const dispatch = useDispatch();

    // const status = useSelector(
    //     (state: { navbar: { status: string } }) => state.navbar.status
    // );
    // const menu: { visible: MenuItem[]; hidden: MenuItem[] } = useSelector(
    //     menuSelector
    // );
    // const submenu: MenuItem[] = useSelector(submenuSelector);

    // useEffect(() => {
    //     if (status == 'IDLE') {
    //         dispatch(getNavbar());
    //     }
    // }, []);

    return (
        <>
            {!mod && (
                <div className="infinite-line">
                    <div className="infinite-line__block">
                        <p>Как жить экологично</p>
                        <p>Как жить экологично</p>
                        <p>Как жить экологично</p>
                        <p>Как жить экологично</p>
                    </div>
                </div>
            )}
            {/*{mod && mod === '_2' && (*/}
            {/*    <div className="infinite-line _2">*/}
            {/*        <div className="infinite-line__block _2">*/}
            {/*            <p>Инструкции</p>*/}
            {/*            <p>Инструкции</p>*/}
            {/*            <p>Инструкции</p>*/}
            {/*            <p>Инструкции</p>*/}
            {/*            <p>Инструкции</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
}
