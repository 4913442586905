export function mapArrayNumberToStringValues(
    arrayNumber: number[],
    arrayObject: any[]
) {
    return arrayNumber
        ? arrayNumber.map(c => arrayObject[c].name).join(', ')
        : '';
}

export function removeAllHTMLAndUnicodeSymbols(string: string) {
    return string
        .replace(/(&.+;)|(<[^>]*>)|(\\n)|\s+/gi, ' ')
        .replace(/\s+/gi, ' ')
        .trim();
}

export function getParentSlug(post: { categories: any[] }, categories: any[]) {
    return categories[post.categories[post.categories.length - 1]].slug;
}

export function getFullPath(arrayPath: string[]) {
    return arrayPath.reduce((fullPath, slug) => fullPath + '/' + slug, '');
}
